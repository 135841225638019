import * as React from "react"
import Layout from "../layout"

const About = () => (
  <>
    <Layout>
      <p> about</p>
    </Layout>
  </>
)

export default About
